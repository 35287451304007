<template>
  <div class="resume">
    <div class="btn-rollback" @click="rollback">
      <i class="fal fa-long-arrow-left"></i>
    </div>
    <v-row class="d-flex flex-column align-center">
      <v-col cols="6" v-for="(item,index) in cardResume" :key="index">
        <div class="card-resume" @click="manageResume(item.id)">
          <h1 v-if="$i18n.locale === 'en'"> {{ item.name }}</h1>
          <h1 v-else>{{ item.name_la }}</h1>
          <div class="circle-resume">
            <i :class="item.icon"/>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cardResume: [
        {
          id: 1,
          name: "Education",
          name_la: "ຈັດການຂໍ້ມູນວຸດທິການສຶກສາ",
          icon: "fas fa-graduation-cap"
        },
        {
          id: 2,
          name: "Skill",
          name_la: "ຈັດການຂໍ້ມູນທັກສະ",
          icon: "fas fa-file-signature"
        },
        {
          id: 3,
          name_la: "ຈັດການຂໍ້ມູນປະສົບການ",
          icon: "fas fa-file-certificate"
        }
      ],
      employee_id: this.$route.params.employee_id,
    }
  },
  methods: {
    rollback() {
      this.$router.push({
        name: "employee.index"
      })
    },
    manageResume(resume_id) {
      if (resume_id === 1) {
        this.$router.push({
          name: "education.index",
          params: {
            employee_id: this.employee_id
          }
        })
      } else if (resume_id === 2) {
        this.$router.push({
          name: "skill.index",
          params: {
            employee_id: this.employee_id
          }
        })
      } else {
        this.$router.push({
          name: "experiences.index",
          params: {
            employee_id: this.employee_id
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.resume {
  width: 100%;
  height: 700px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  background-color: #FFFFFF;
  border-left: 2px solid #eeeeee;

  .btn-rollback {
    width: 80px;
    border-radius: 30px;
    height: 30px;
    background: rgb(106, 187, 245);
    position: absolute;
    top: 20px;
    left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    color: #FFFFFF;
    cursor: pointer;
  }


  .card-resume {
    width: 100%;
    height: 180px;
    background-color: #FFFFFF;
    border: 2px solid #eeeeee;
    box-shadow: 0 2px 28px 0 rgb(0 0 0 / 8%);
    margin: 0.8rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;

    h1 {
      font-size: 1.2rem;
      font-weight: normal;
      font-family: $font-family;
      position: absolute;
      top: 10px;
      left: 20px;
    }

    .circle-resume {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background-color: rgba(69, 170, 242, 0.8);
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        font-size: 2.4rem;
        color: #FFFFFF;
      }
    }
  }
}
</style>
